import apiClient from "../../utils/apiClient";
import { apiDetails } from "../../apiDetails";

export const REQUEST_STARTED = 'REQUEST_STARTED';
export const REQUEST_ENDED = 'REQUEST_ENDED';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
export const EDIT_PROFILE_SUCCEEDED = 'EDIT_PROFILE_SUCCEEDED';

const { login, signup, editProfileAPI } = apiDetails.auth;

export const loginUser = (data, toast) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: REQUEST_STARTED
      })
      const response = await apiClient(login.url, login.method, { data });
      dispatch({
        type: LOGIN_SUCCEEDED,
        payload: response.data,
      });
      toast('Logged in successfully.', { appearance: "success", autoDismiss: true });
      resolve();
    } catch (err) {
      dispatch({
        type: REQUEST_ENDED
      })
      toast(err.response?.data.message ? err.response?.data.message : 'Oops. We are having trouble logging you in.', { appearance: "error", autoDismiss: false });
      reject();
    }
  });
};

export const registerUser = (data, toast) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: REQUEST_STARTED
      })
      const response = await apiClient(signup.url, signup.method, { data });
      dispatch({
        type: SIGNUP_SUCCEEDED,
        payload: response.data,
      });
      toast(`Signed up successfully. Email sent to ${response.data.userDetail?.email} for verification.`, { appearance: "success", autoDismiss: false });
      resolve();
    } catch (err) {
      dispatch({
        type: REQUEST_ENDED
      })
      toast(err.response?.data.message ? err.response.data.message : 'Oops. We are having trouble signing you up.', { appearance: "error", autoDismiss: false });
      reject();
    }
  });
};

export const logoutUser = (toast) => (dispatch) => {
  dispatch({
    type: LOGOUT_SUCCEEDED
  })
  toast('You have been logged out.', { appearance: "error", autoDismiss: true });
};

export const editProfile = (userId, data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: REQUEST_STARTED
      })
      const newUrl = editProfileAPI.url.replace("{id}", userId);
      const response = await apiClient(newUrl, editProfileAPI.method, { data });
      dispatch({
        type: EDIT_PROFILE_SUCCEEDED,
        payload: data,
      });
      resolve(response);
    } catch (err) {
      dispatch({
        type: REQUEST_ENDED
      })
      reject(err);
    }
  });
};
