import apiClient from "../../utils/apiClient";
import { apiDetails } from "../../apiDetails";

export const GET_HOMES = "GET_HOMES";

const { getHomes } = apiDetails.homes;

export const fetchHomes = () => async (dispatch) => {
  const homeData = await apiClient(getHomes.url, getHomes.method);
  dispatch({
    type: GET_HOMES,
    payload: homeData.data || { smartBar: [], homemodal: [] },
  });
};
