import apiClient from "../../utils/apiClient";
import { apiDetails } from "../../apiDetails";

export const ADD_TO_CART = "ADD_TO_CART";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const DELETE_CART_ALL = "DELETE_CART_ALL";
export const CLEAR_CART = "CLEAR_CART";

export const EDIT_CART_ITEM = "EDIT_CART_ITEM";

const { addItem, getItems, editItem, deleteItem, deleteAll } = apiDetails.cart;

export const addToCart =
  ({ product, quantityCount }, addToast) =>
    async (dispatch, getState) => {
      const { cartItems } = getState().cartData;
      try {
        const response = await apiClient(addItem.url, addItem.method, {
          data: { variationId: product?._id, quantity: quantityCount },
        });
        dispatch({
          type: ADD_TO_CART,
          payload: response.data,
        });
        if (addToast) {
          addToast("Added To Cart", { appearance: "success", autoDismiss: true });
        }
      } catch {
        dispatch({
          type: ADD_TO_CART,
          payload: cartItems,
        });
        addToast("Failed To Add item in Cart", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    };

export const editCartItem = (cartItem, change, toast) => async (dispatch) => {
  if (cartItem.quantity < 2 && change == -1) {
    return;
  }
  cartItem.quantity = cartItem.quantity + change;

  dispatch({
    type: EDIT_CART_ITEM,
    payload: cartItem,
  });
  const newUrl = editItem.url.replace("{id}", cartItem._id);
  try {
    await apiClient(newUrl, editItem.method, {
      data: {
        quantity: cartItem.quantity,
      },
    });
  } catch (_err) {
    cartItem.quantity = cartItem.quantity - change;
    dispatch({
      type: EDIT_CART_ITEM,
      payload: cartItem,
    });
    toast("Quantity Change Failed", { appearance: "error", autoDismiss: true });
  }
};

export const deleteCartItem = (id, toast) => async (dispatch, getState) => {
  const { cartItems } = getState().cartData;
  const updatedCartItems = cartItems.filter((item) => item._id !== id);
  dispatch({
    type: DELETE_CART_ITEM,
    payload: updatedCartItems,
  });
  try {
    const newUrl = deleteItem.url.replace("{id}", id);
    await apiClient(newUrl, deleteItem.method);
  } catch {
    dispatch({
      type: DELETE_CART_ITEM,
      payload: cartItems,
    });
    toast("Item removed failed", { appearance: "error", autoDismiss: true });
  }
};

export const deleteCartAll = (addToast) => async (dispatch, getState) => {
  const { cartItems } = getState().cartData;
  dispatch({
    type: DELETE_CART_ALL,
    payload: [],
  });
  if (addToast) {
    addToast("Removed All data from cart", {
      appearance: "error",
      autoDismiss: true,
    });
  }

  try {
    const newUrl = deleteAll.url;
    await apiClient(newUrl, deleteItem.method);
  } catch {
    dispatch({
      type: DELETE_CART_ALL,
      payload: cartItems,
    });
  }
};

export const clearCart = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CART,
    payload: [],
  });
};

export const getCartItems = (token) => async (dispatch) => {
  const cartItems = await apiClient(getItems.url, getItems.method, {
    authToken: token,
  });
  dispatch({
    type: GET_CART_ITEMS,
    payload: cartItems.data || [],
  });
};
