import { GET_HOMES } from "../actions/homeAction";

const initialState = {
  homes: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_HOMES:
      return { ...state, homes: payload };
    default:
      return state;
  }
};
