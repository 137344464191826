import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import tawkTo from "tawkto-react";

import RouterRoute from "./routes";

const tawkToPropertyId = process.env.REACT_APP_TWAK_PROPERTY_ID;
const tawkToKey = process.env.REACT_APP_TWAK_KEY;

const App = (props) => {
  // useEffect(() => {
  //   tawkTo(tawkToPropertyId, tawkToKey);
  // }, []);

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <RouterRoute {...props} />
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
