export const apiDetails = {
  product: {
    getFilterLists: {
      url: "products/filterList",
      method: "GET",
    },
    getAllProducts: {
      url: "products",
      method: "GET",
    },
    getProductDetail: {
      url: "products/{slug}",
      method: "GET",
    },
  },

  cart: {
    addItem: {
      url: "/productCart",
      method: "POST",
    },
    getItems: {
      url: "/productCart",
      method: "GET",
    },
    editItem: {
      url: "/productCart/{id}",
      method: "PUT",
    },
    deleteItem: {
      url: "/productCart/{id}",
      method: "DELETE",
    },
    deleteAll: {
      url: "/productCart",
      method: "DELETE",
    },
  },

  page: {
    getPages: {
      url: "/pages/public",
      method: "GET",
    },
  },

  auth: {
    login: {
      url: "/users/login",
      method: "POST",
    },
    signup: {
      url: "/users/signup",
      method: "POST",
    },
    editProfileAPI: {
      url: "/users/{id}",
      method: "PUT",
    },
  },

  quotation: {
    sendQuotation: {
      url: "/quotation",
      method: "POST",
    },
  },

  others: {
    getHeaderList: {
      url: "/others/headerList",
      method: "GET",
    },
    contact: {
      url: "/others/contactUs",
      method: "POST",
    },
  },

  productOrder: {
    checkout: {
      url: "/productOrder",
      method: "POST",
    },
    orderHistory: {
      url: "/productOrder/user",
      method: "GET",
    },
    orderHistoryDetail: {
      url: "/productOrder/user/{id}",
      method: "GET",
    },
  },

  reviews: {
    add: {
      url: "/reviews",
      method: "POST",
    },
    fetch: {
      url: "/reviews/{productId}",
      method: "GET",
    },
  },

  blogs: {
    getBlogs: {
      url: "/blogs",
      method: "GET",
    },
    fetchOne: {
      url: "/blogs/{slug}",
      method: "GET",
    },
    fetchFeatured: {
      url: "/blogs/featured",
      method: "GET",
    },
  },

  homes: {
    getHomes: {
      url: "/others/homes",
      method: "GET",
    },
  },

  newsletter: {
    subscribe: {
      url: "/newsletter",
      method: "POST",
    },
  },
};
