import apiClient from "../../utils/apiClient";
import { apiDetails } from "../../apiDetails";

export const GET_PAGES = "GET_PAGES";

const { getPages } = apiDetails.page;

export const fetchPages = () => async (dispatch) => {
  const pageData = await apiClient(getPages.url, getPages.method);
  dispatch({
    type: GET_PAGES,
    payload: pageData.data || [],
  });
};
