import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import pageReducer from "./pageReducer";
import blogReducer from "./blogReducer";

import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import authReducer from './authReducer';
import homeReducer from './homeReducer';
import { filterReducer } from "./filterReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  cartData: cartReducer,
  blogData: blogReducer,
  homeData: homeReducer,
  pageData: pageReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  filterData: filterReducer,
  authData: authReducer
});

export default rootReducer;
