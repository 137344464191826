import {
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCT_SUCCESS,
} from "../actions/productActions";

const initState = {
  products: [],
  product: {},
  pagination: {
    totalCount: 1,
    currentCount: 1,
    currentPage: 1,
    totalPage: 1,
  },
};

const productReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: payload.productList,
        pagination: payload.pagination,
      };

    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        product: payload,
      };

    default:
      return state;
  }
};

export default productReducer;
