import { apiDetails } from "../../apiDetails";
import apiClient from "../../utils/apiClient";
const { method, url } = apiDetails.product.getFilterLists;

export const FETCH_FILTER_SUCCESS = "FETCH_FILTER_SUCCESS";

const fetchFiltersSuccess = (filterList) => ({
  type: FETCH_FILTER_SUCCESS,
  payload: filterList,
});

// fetch products
export const fetchFilterList = () => {
  return async (dispatch) => {
    const filterList = await apiClient(url, method);
    dispatch(fetchFiltersSuccess(filterList.data || {}));
  };
};
