import * as storageUtils from '../../utils/storageUtils';

import {
  REQUEST_STARTED,
  REQUEST_ENDED,
  LOGIN_SUCCEEDED,
  SIGNUP_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  EDIT_PROFILE_SUCCEEDED
} from "../actions/authActions";

const initialState = {
  userDetail: {},
  isSubmitting: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case REQUEST_STARTED:
      return { ...state, isSubmitting: true };

    case REQUEST_ENDED:
      return { ...state, isSubmitting: false };

    case LOGIN_SUCCEEDED:
      storageUtils.set('local', 'userToken', payload.token);
      storageUtils.set('local', 'userDetail', JSON.stringify(payload.userDetail));
      return { ...state, userDetail: payload.userDetail, isSubmitting: false };

    case SIGNUP_SUCCEEDED:
      storageUtils.set('local', 'userToken', payload.token);
      storageUtils.set('local', 'userDetail', JSON.stringify(payload.userDetail));
      return { ...state, userDetail: payload.userDetail, isSubmitting: false };

    case LOGOUT_SUCCEEDED:
      storageUtils.clear('local');
      storageUtils.clear(); // clear session storage
      return { ...state, userDetail: {} };

    case EDIT_PROFILE_SUCCEEDED:
      let userDetail = storageUtils.get('local', 'userDetail');
      if (userDetail) {
        userDetail = JSON.parse(userDetail);
        userDetail = { ...userDetail, ...payload }
        storageUtils.set('local', 'userDetail', JSON.stringify(userDetail));
      };
      return { ...state, userDetail: { ...state.userDetail, ...payload }, isSubmitting: false };

    default:
      return state;
  }
};
