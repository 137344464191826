import {
  GET_BLOGS,
  GET_FEATURED_BLOGS,
  GET_BLOG_DETAILS,
} from "../actions/blogsAction";

const initialState = {
  blogs: [],
  featuredBlogs: [],
  blogDetail: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BLOGS:
      return { ...state, blogs: payload };
    case GET_FEATURED_BLOGS:
      return { ...state, featuredBlogs: payload };
    case GET_BLOG_DETAILS:
      return { ...state, blogDetail: payload };

    default:
      return state;
  }
};
