import axios from "axios";
import { set } from "../utils/storageUtils";

const baseURL =
  process.env.REACT_APP_BASEURL || "https://electrogadgets.ml/api/v1";

export default async function client(url = "/", method = "GET", options = {}) {
  const { config, setCookie = false, data, params } = options;
  let token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : "";
  let guesttoken = localStorage.getItem("guesttoken");
  let defaultConfig = {
    baseURL,
    data,
    url,
    method,
    timeout: 30000,
    withCredentials: !!setCookie ? true : false,
    params,
    headers: {
      "Content-Type": "application/json",
      ...config?.headers,
    },
    cancelToken: axios.CancelToken.source().token,
  };

  if (token && token !== "undefined") {
    defaultConfig.headers.Authorization = `Bearer ${token}`;
  }

  if (guesttoken && guesttoken !== "undefined") {
    defaultConfig.headers["guesttoken"] = guesttoken;
  }
  return axios.request(defaultConfig).then(
    async (res) => {
      if (res.status === 401) {
        window.location.assign(window.location.href);
        return Promise.reject({ message: "Please re-authenticate" });
      }
      if (res?.headers?.guesttoken) {
        token = res.headers?.guesttoken;
        if (token && token !== "undefined") {
          set("local", "guesttoken", token);
        }
      }
      return res.data;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
