import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";

import ProtectedRoute from "./ProtectedRoute";
import ScrollToTop from "../helpers/scroll-top";

import { fetchPages } from "../redux/actions/pagesAction";

import { fetchBlogs } from "../redux/actions/blogsAction";

import { fetchHomes } from "../redux/actions/homeAction";

// home pages
const Home = lazy(() => import("../pages/home"));

// Home Furniture, Home Electronics
const BlogListElectro = lazy(() => import("../pages/blog/BlogListElectro"));
const BlogItemElectro = lazy(() => import("../pages/blog/BlogItemElectro"));

// shop pages
const ShopGridStandardElectro = lazy(() =>
  import("../pages/shop/ShopGridStandardElectro")
);

// product pages
const ProductFixedImage = lazy(() =>
  import("../pages/shop-product/ProductFixedImage")
);

// Auth Pages
const Register = lazy(() => import("../pages/register"));
const Login = lazy(() => import("../pages/login"));
const LogOut = lazy(() => import("../pages/other/LogOut"));

// Contact Page
const Contact = lazy(() => import("../pages/contact"));

// Quotation Page
const Quotation = lazy(() => import("../pages/quotation"));

// Other Pages
const MyAccount = lazy(() => import("../pages/my-account"));
const OrderHistory = lazy(() => import("../pages/order-history"));
const OrderHistoryDetail = lazy(() => import("../pages/order-history-detail"));

const Cart = lazy(() => import("../pages/other/Cart"));
const Wishlist = lazy(() => import("../pages/other/Wishlist"));
const Compare = lazy(() => import("../pages/other/Compare"));
const Checkout = lazy(() => import("../pages/other/Checkout"));
const About = lazy(() => import("../pages/other/About"));

const NotFound = lazy(() => import("../pages/other/NotFound"));

const RouterRoute = (props) => {
  const { fetchPages, fetchBlogs, fetchHomes } = props;
  const { pages, blogs } = props;
  console.log("blogs are", blogs);
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("../translations/english.json"),
          fn: require("../translations/french.json"),
          de: require("../translations/germany.json"),
        },
      })
    );
  });

  useEffect(() => {
    fetchPages();
    fetchBlogs();
    fetchHomes();
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Switch>
            {/* Home Page */}
            <Route exact path={process.env.PUBLIC_URL + "/"} component={Home} />

            {/* Auth Pages */}
            <Route
              exact
              path={process.env.PUBLIC_URL + "/login"}
              component={Login}
            />
            {/* //#region redirect */}
            <Route
              exact
              path={process.env.PUBLIC_URL + "/Iphone-repair"}
              render={() => <Redirect to="/Iphone-repair-launceston" />}
            />

            <Route
              exact
              path={process.env.PUBLIC_URL + "/slug"}
              render={() => <Redirect to="/" />}
            />

            <Route
              exact
              path={process.env.PUBLIC_URL + "/register"}
              component={Register}
            />
            <Route
              path={process.env.PUBLIC_URL + "/logout"}
              component={LogOut}
            />

            {/* Shop pages */}
            <Route
              exact
              path={process.env.PUBLIC_URL + "/products"}
              component={ShopGridStandardElectro}
            />

            {/* Shop product pages */}
            <Route
              exact
              path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
              component={ProductFixedImage}
            />

            {/* Shop product pages */}
            <Route
              exact
              path={process.env.PUBLIC_URL + "/products/:slug"}
              component={ProductFixedImage}
            />

            <ProtectedRoute
              path={process.env.PUBLIC_URL + "/my-account"}
              component={MyAccount}
            />

            <ProtectedRoute
              exact
              path={process.env.PUBLIC_URL + "/order-history"}
              component={OrderHistory}
            />

            <ProtectedRoute
              exact
              path={process.env.PUBLIC_URL + "/order-history/:id"}
              component={OrderHistoryDetail}
            />

            <Route path={process.env.PUBLIC_URL + "/cart"} component={Cart} />
            <Route
              path={process.env.PUBLIC_URL + "/wishlist"}
              component={Wishlist}
            />
            <Route
              path={process.env.PUBLIC_URL + "/compare"}
              component={Compare}
            />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              component={Checkout}
            />
            <Route path={process.env.PUBLIC_URL + "/about"} component={About} />

            {blogs.map((item) => {
              return (
                <Route
                  render={(props) => (
                    <BlogItemElectro {...props} slug={item.slug} />
                  )}
                  path={process.env.PUBLIC_URL + "/blogs/" + item.slug}
                  // component={BlogItemElectro}
                  state={{ pageType: item.pageType, theSlug: "anil" }}
                  exact
                />
              );
            })}
            <Route
              path={process.env.PUBLIC_URL + "/blogs"}
              component={BlogListElectro}
            />

            {/* Contact Page */}
            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              component={Contact}
            />

            {/* Quotation Page */}
            <Route
              path={process.env.PUBLIC_URL + "/get-quotation"}
              component={Quotation}
            />

            {pages.map((item) => {
              if (
                item.pageType == "categories" ||
                item.pageType == "subCategories" ||
                item.pageType == "models" ||
                item.pageType == "brands"
              ) {
                if (item.pageSlug == "samsung/galaxy-series") {
                  console.log("this is the item", item);
                }
                return (
                  <Route
                    render={(props) => (
                      <ShopGridStandardElectro
                        {...props}
                        pageType={item.pageType}
                        pageSlug={item.pageSlug}
                      />
                    )}
                    path={process.env.PUBLIC_URL + "/" + item.pageSlug}
                    // component={ShopGridStandardElectro}
                    state={{ pageType: item.pageType }}
                    exact
                  />
                );
              }
            })}

            <Route
              path={process.env.PUBLIC_URL + "/not-found"}
              component={NotFound}
            />

            <Route exact component={NotFound} />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

RouterRoute.propTypes = {
  dispatch: PropTypes.func,
  pages: PropTypes.array,
  blogs: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    pages: state.pageData.pages,
    blogs: state.blogData.blogs,
  };
};

const mapDispatchToProps = {
  fetchPages,
  fetchBlogs,
  fetchHomes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(RouterRoute));
