import {
  ADD_TO_CART,
  DELETE_CART_ITEM,
  EDIT_CART_ITEM,
  GET_CART_ITEMS,
  DELETE_CART_ALL,
  CLEAR_CART
} from "../actions/cartActions";

const initialState = {
  cartItems: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CART_ITEMS:
      return { ...state, cartItems: payload };
    case EDIT_CART_ITEM:
      const updatedCartItem = state.cartItems.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
      return { ...state, cartItems: updatedCartItem };

    case DELETE_CART_ITEM:
      return { ...state, cartItems: payload };

    case DELETE_CART_ALL:
        return { ...state, cartItems: payload };

    case CLEAR_CART:
        return { ...state, cartItems: payload };

    case ADD_TO_CART:
      return { ...state, cartItems: payload };
    default:
      return state;
  }
};
