import apiclient from "../../utils/apiClient";
import { apiDetails } from "../../apiDetails";
import { get } from "../../utils/storageUtils";
import { getCartItems } from "./cartActions";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";

const { getAllProducts, getProductDetail } = apiDetails.product;

const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

const fetchProductSuccess = (products) => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: products,
});

// fetch products
export const fetchProducts = (params) => {
  return async (dispatch) => {
    const products = await apiclient(
      getAllProducts.url,
      getAllProducts.method,
      { params }
    );
    dispatch(fetchProductsSuccess(products.data || {}));
    dispatch(getCartItems());
  };
};

export const fetchProduct = (slug) => {
  const newUrl = getProductDetail.url.replace("{slug}", slug);
  return async (dispatch) => {
    const product = await apiclient(newUrl, getProductDetail.method);
    dispatch(fetchProductSuccess(product.data || {}));
  };
};
