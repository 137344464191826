import { FETCH_FILTER_SUCCESS } from "../actions/filterActions";
const initialState = {
  categories: [],
  tags: [],
  modelBrands: []
};

export const filterReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case FETCH_FILTER_SUCCESS:
      return {
        ...state,
        categories: payload.mergedCategories,
        tags: payload.tagList,
        modelBrands: payload.mergedModelBrand
      };
    default:
      return state;
  }
};
