import { GET_PAGES } from "../actions/pagesAction";

const initialState = {
  pages: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PAGES:
      return { ...state, pages: payload };

    default:
      return state;
  }
};
