import apiClient from "../../utils/apiClient";
import { apiDetails } from "../../apiDetails";

export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOG_DETAILS = "GET_BLOG_DETAILS";
export const GET_FEATURED_BLOGS = "GET_FEATURED_BLOGS";

const { getBlogs, fetchOne, fetchFeatured } = apiDetails.blogs;

export const fetchFeaturedBlogs = async () => {
  const featuredBlogsData = await apiClient(
    fetchFeatured.url,
    fetchFeatured.method
  );
    return featuredBlogsData;
};
export const fetchBlogs = () => async (dispatch) => {

  const blogData = await apiClient(getBlogs.url, getBlogs.method);
  console.log("Blog data is ",blogData)

  dispatch({
    type: GET_BLOGS,
    payload: blogData.data || [],
  });
};

export const fetchBlogDetails = (slug) => async (dispatch) => {
  try{
  const newUrl = fetchOne.url.replace("{slug}", slug);

  console.log("one Blog data is ",oneBlogData)
  const oneBlogData = await apiClient(newUrl, fetchOne.method);
  dispatch({
    type: GET_BLOG_DETAILS,
    payload: oneBlogData?.data || [],
  });
}
catch(err){
  dispatch({
    type: GET_BLOG_DETAILS,
    payload: [],
  });
}
};